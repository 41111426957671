import React, { Component } from "react";
import "./New_Css.css";
import SocialMedia from "../../components/socialMedia/SocialMedia";



// const ContactData = contactPageData.contactSection;
// const blogSection = contactPageData.blogSection;
// const addressSection = contactPageData.addressSection;
// const phoneSection = contactPageData.phoneSection;

class Contact_compo extends Component {
    render() {
        const theme = this.props.theme;
        return (
            <main className="main-1">
                <section className="contact-main-new">
                    <div>
                        <h1 className="Heading-1"> Contact Me </h1>
                        <h4 className="Heading-2">Feel Free to contact us any time. We will get back to you as soon as we can!. </h4>
                    </div>

                    <form className="Form_Data">
                        <input type="text" placeholder="Name" />
                        <input type="email" placeholder="Email" />
                        <input type="textarea" placeholder="Your Message" />
                        <button type="submit">Send Now</button>
                    </form>
                    <div className="Sample-or-contact">
                        <div className="LIne"></div>
                        <p>OR</p>
                        <div className="LIne"></div>
                    </div>
                    <div  className="Social-Contact">
                        <SocialMedia/>
                    </div>

                </section>


                <section className="contact-info">
                    <h2> Contact Info</h2>
                    <div className="Social-Add">
                        {/* <div> */}
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" style={{ height: "30px", width: "30px" }}>
                                <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 1.5H8.25A2.25 2.25 0 006 3.75v16.5a2.25 2.25 0 002.25 2.25h7.5A2.25 2.25 0 0018 20.25V3.75a2.25 2.25 0 00-2.25-2.25H13.5m-3 0V3h3V1.5m-3 0h3m-3 18.75h3" />
                            </svg>
                        {/* </div> */}

                        <a href="tel:+919783273547"> +91 9783273547</a>
                    </div>
                    <div className="Social-Add">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" style={{ height: "30px", width: "30px" }}>
                            <path stroke-linecap="round" stroke-linejoin="round" d="M21.75 9v.906a2.25 2.25 0 01-1.183 1.981l-6.478 3.488M2.25 9v.906a2.25 2.25 0 001.183 1.981l6.478 3.488m8.839 2.51l-4.66-2.51m0 0l-1.023-.55a2.25 2.25 0 00-2.134 0l-1.022.55m0 0l-4.661 2.51m16.5 1.615a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V8.844a2.25 2.25 0 011.183-1.98l7.5-4.04a2.25 2.25 0 012.134 0l7.5 4.04a2.25 2.25 0 011.183 1.98V19.5z" />
                        </svg>
                        <a href="mailto:contact@HemangJiwnani.com"> Hemang.jiwnani2020@gmail.com</a>
                    </div>
                    <div className="Social-Add location">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" style={{ height: "60px", width: "60px" }}>
                            <path stroke-linecap="round" stroke-linejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" />
                            <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" />
                        </svg>

                        <h3> 776 New Swami Nagar Teekri Madri Link Road, Udaipur, Rajasthan</h3>
                    </div>
                </section>

                <div className="BackLine"/>
            </main>

        );
    }
}

export default Contact_compo;
