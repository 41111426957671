/* Change this file to get your personal Porfolio */

// Website related settings
const settings = {
  isSplash: false, // Change this to false if you don't want Splash screen.
};

//SEO Related settings
const seo = {
  title: "Hemang's Portfolio",
  description:
    "A passionate individual who always thrives to work on end to end products which develop sustainable and scalable social and technical systems to create impact.",
  og: {
    title: "Hemang Jiwnani Portfolio",
    type: "website",
    url: "http://ashutoshhathidara.com/", 
  },
};

//Home Page
const greeting = {
  title: "Er. Hemang Jiwnani",
  logo_name: " HemangJiwnani", 
  nickname: "MacFox",
  subTitle:
    "A passionate engineer who loves to teach machine's aka ML. With the same working on end to end products which develop sustainable and scalable technical system to create a change in this world.",
  resumeLink:
    "https://drive.google.com/file/d/1FL_wydnPF2SWCdw2goYAgZ1KKBx8TpcD/view?usp=sharing",
  portfolio_repository: "https://github.com/ashutosh1919/masterPortfolio",
  githubProfile: "https://github.com/hjiwnain",
};

const socialMediaLinks = [

  {
    name: "Github",
    link: "https://github.com/hjiwnain",
    fontAwesomeIcon: "fa-github", // Reference https://fontawesome.com/icons/github?style=brands
    backgroundColor: "#181717", // Reference https://simpleicons.org/?q=github
  },
  {
    name: "LinkedIn",
    link: "https://www.linkedin.com/in/hemangjiwnani/",
    fontAwesomeIcon: "fa-linkedin-in", // Reference https://fontawesome.com/icons/linkedin-in?style=brands
    backgroundColor: "#0077B5", // Reference https://simpleicons.org/?q=linkedin
  },
  {
    name: "Gmail",
    link: "mailto:Hemang.jiwnani2020@gmail.com",
    fontAwesomeIcon: "fa-google", // Reference https://fontawesome.com/icons/google?style=brands
    backgroundColor: "#D14836", // Reference https://simpleicons.org/?q=gmail
  },
  {
    name: "Twitter",
    link: "https://twitter.com/JiwnaniHemang",
    fontAwesomeIcon: "fa-twitter", // Reference https://fontawesome.com/icons/twitter?style=brands
    backgroundColor: "#1DA1F2", // Reference https://simpleicons.org/?q=twitter
  },
  {
    name: "Discord",
    link: "https://discord.gg/B2Djggntmy",
    fontAwesomeIcon: "fa-brands fa-discord", // Reference https://fontawesome.com/icons/facebook-f?style=brands
    backgroundColor: "#1877F2", // Reference https://simpleicons.org/?q=facebook
  },
];

const skills = {
  data: [
    {
      title: "NLP & DL Research/Deployment",
      fileName: "DataScienceImg",
      skills: [
        "⚡ Experience in Development and designing language understanding systems for using text representation techniques more efficiently.",
        "⚡ Specialist in structuring unstructured data and performing EDA techniques to explain data and create interactive dashboards for presentation.",
        "⚡ Experience of working with Computer Vision and NLP projects",
        "⚡ Complex quantitative modelling for dynamic forecasting and time series analysis",
      ],
      softwareSkills: [
        {
          skillName: "Tensorflow",
          fontAwesomeClassname: "logos-tensorflow",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "Keras",
          fontAwesomeClassname: "simple-icons:keras",
          style: {
            backgroundColor: "white",
            color: "#D00000",
          },
        },
        {
          skillName: "PyTorch",
          fontAwesomeClassname: "logos-pytorch",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "Python",
          fontAwesomeClassname: "ion-logo-python",
          style: {
            backgroundColor: "transparent",
            color: "#3776AB",
          },
        },
      ],
    },
    {
      title: "Full Stack Development",
      fileName: "FullStackImg",
      skills: [
        "⚡ Building responsive website front end using React",
        "⚡ Developing mobile applications using java",
        "⚡ Creating application backend in Flask and FastAPI",
        "⚡ Deploying and building project using streamlit",
      ],
      softwareSkills: [
        {
          skillName: "HTML5",
          fontAwesomeClassname: "simple-icons:html5",
          style: {
            color: "#E34F26",
          },
        },
        {
          skillName: "CSS3",
          fontAwesomeClassname: "fa-css3",
          style: {
            color: "#1572B6",
          },
        },
        {
          skillName: "Sass",
          fontAwesomeClassname: "simple-icons:sass",
          style: {
            color: "#CC6699",
          },
        },
        {
          skillName: "Flask",
          fontAwesomeClassname: "simple-icons:flask",
          style: {
            color: "red",
          },
        },
        {
          skillName: "ReactJS",
          fontAwesomeClassname: "simple-icons:react",
          style: {
            color: "#61DAFB",
          },
        },
        {
          skillName: "Streamlit",
          fontAwesomeClassname: "simple-icons:streamlit",
          style: {
            color: "red",
          },
        },
        {
          skillName: "FASTAPI",
          fontAwesomeClassname: "simple-icons:fastapi"
        },
        {
          skillName: "Java",
          fontAwesomeClassname: "simple-icons:java",
          style: {
            color: "#red",
          },
        },
        {
          skillName: "NPM",
          fontAwesomeClassname: "simple-icons:npm",
          style: {
            color: "#CB3837",
          },
        },
      ],
    },
    {
      title: "Cloud Infra-Architecture",
      fileName: "CloudInfraImg",
      skills: [
        "⚡ Experience working on multiple cloud platforms including AWS and GCP.",
        "⚡ Deploying deep learning models on different platforms like Render.com, Heroku, Streamlit, etc.",
        "⚡ Deploying ML/DL models on cloud to use on mobile devices.",
        "⚡ Deploying ML/DL apps on Heroku to serve as inference-engines."
      ],
      softwareSkills: [
        {
          skillName: "GCP",
          fontAwesomeClassname: "simple-icons:googlecloud",
          style: {
            color: "#4285F4",
          },
        },
        {
          skillName: "AWS",
          fontAwesomeClassname: "simple-icons:amazonaws",
          style: {
            color: "#FF9900",
          },
        },
        {
          skillName: "Azure",
          fontAwesomeClassname: "simple-icons:microsoftazure",
          style: {
            color: "#0089D6",
          },
        },
        {
          skillName: "Firebase",
          fontAwesomeClassname: "simple-icons:firebase",
          style: {
            color: "#FFCA28",
          },
        },
        {
          skillName: "PostgreSQL",
          fontAwesomeClassname: "simple-icons:postgresql",
          style: {
            color: "#336791",
          },
        },
        {
          skillName: "MongoDB",
          fontAwesomeClassname: "simple-icons:mongodb",
          style: {
            color: "#47A248",
          },
        },
        {
          skillName: "Render.com",
          fontAwesomeClassname: "simple-icons:render",
          style: {
            color: "black",
          },
        },
      ],
    },
    // {
    //   title: "UI/UX Design",
    //   fileName: "DesignImg",
    //   skills: [
    //     "⚡ Designing highly attractive user interface for mobile and web applications",
    //     "⚡ Customizing logo designs and building logos from scratch",
    //     "⚡ Creating the flow of application functionalities to optimize user experience",
    //   ],
    //   softwareSkills: [
    //     {
    //       skillName: "Adobe XD",
    //       fontAwesomeClassname: "simple-icons:adobexd",
    //       style: {
    //         color: "#FF2BC2",
    //       },
    //     },
    //     {
    //       skillName: "Figma",
    //       fontAwesomeClassname: "simple-icons:figma",
    //       style: {
    //         color: "#F24E1E",
    //       },
    //     },
    //     {
    //       skillName: "Adobe Illustrator",
    //       fontAwesomeClassname: "simple-icons:adobeillustrator",
    //       style: {
    //         color: "#FF7C00",
    //       },
    //     },
    //     {
    //       skillName: "Inkscape",
    //       fontAwesomeClassname: "simple-icons:inkscape",
    //       style: {
    //         color: "#000000",
    //       },
    //     },
    //   ],
    // },
  ],
};

// Education Page
const competitiveSites = {
  competitiveSites: [
    {
      siteName: "LeetCode",
      iconifyClassname: "simple-icons:leetcode",
      style: {
        color: "#F79F1B",
      },
      profileLink: "https://leetcode.com/MacFox/",
    },
    {
      siteName: "HackerRank",
      iconifyClassname: "simple-icons:hackerrank",
      style: {
        color: "#2EC866",
      },
      profileLink: "https://www.hackerrank.com/hjiwnani2929",
    },
    {
      siteName: "Codechef",
      iconifyClassname: "simple-icons:codechef",
      style: {
        color: "#5B4638",
      },
      profileLink: "https://www.codechef.com/users/hjiwnani2929",
    },
    {
      siteName: "Codeforces",
      iconifyClassname: "simple-icons:codeforces",
      style: {
        color: "#1F8ACB",
      },
      profileLink: "#",
    },
    {
      siteName: "Hackerearth",
      iconifyClassname: "simple-icons:hackerearth",
      style: {
        color: "#323754",
      },
      profileLink: "#",
    },
    {
      siteName: "Kaggle",
      iconifyClassname: "simple-icons:kaggle",
      style: {
        color: "#20BEFF",
      },
      profileLink: "https://www.kaggle.com/hemangjiwnani",
    },
  ],
};

const degrees = {
  degrees: [
    {
      title: "Vellore Institute of Technology, Bhopal",
      subtitle: "B.Tech. in Computer Engineering",
      logo_path: "vit1.png",
      alt_name: "VIT Bhopal",
      duration: "2020 - 2024",
      descriptions: [
        "⚡ I have studied basic software engineering subjects like DS, Algorithms, DBMS, OS, CA, AI etc.",
        "⚡ Apart from this, I have done courses on Deep Learning, Data Science, Cloud Computing and Full Stack Development.",
        "⚡ I was selected for various hackathon on national and international level and represented my college aswell as country.",
      ],
      website_link: "http://iiitk.ac.in",
    },
  ],
};

const certifications = {
  certifications: [
    {
      title: "National Winner - Smart India Hackathon 2022",
      subtitle: "- MOE [ Ministry of Education ]",
      logo_path: "sih.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/verify/22MTSSC5WDTM",
      alt_name: "SIH",
      color_code: "",
    },
    {
      title: "International Finalist - UNSESCO India Africa Hackathon 2022",
      subtitle: "- MOE [ Ministry of Education ]",
      logo_path: "uia.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/specialization/H8CPSFXAJD2G",
      alt_name: "deeplearning.ai",
      color_code: "",
    },
    {
      title: "National Runner Up - Enosium 2023",
      subtitle: "- IIT Indore",
      logo_path: "enosium.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/specialization/EB4VJARK8647",
      alt_name: "Google",
      color_code: "",
    },
    {
      title: "Applied ML with Python",
      subtitle: "- Michigan University",
      logo_path: "images.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/specialization/PLEAPCSJBZT5",
      alt_name: "IBM",
      color_code: "",
    },
    {
      title: "Paper Presentation - RTACE National Conference 2020",
      subtitle: "- VIT Bhopal University",
      logo_path: "vit1.png",
      certificate_link:
        "https://drive.google.com/file/d/164zKCFOsI4vGqokc-Qj-e_D00kLDHIrG/view",
      alt_name: "Microsoft",
      color_code: "#",
    },
    {
      title: "Python for Everybody",
      subtitle: "- Michigan University",
      logo_path: "images.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/specialization/PLEAPCSJBZT5",
      alt_name: "IBM",
      color_code: "",
    },
    {
      title: "Natural Language Processing",
      subtitle: "- Deeplearning.ai",
      logo_path: "deeplearning_ai_logo.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/verify/6T4DCUGNK8J8",
      alt_name: "deeplearning.ai",
      color_code: "",
    },    
    {
      title: "Data Analyst with Python Training",
      subtitle: "- DevTown",
      logo_path: "dev.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/verify/5JZZM7TNQ2AV",
      alt_name: "Google",
      color_code: "",
    },
    {
      title: "Natural Language Processing Advance",
      subtitle: "- Deeplearning.ai",
      logo_path: "deeplearning_ai_logo.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/verify/6T4DCUGNK8J8",
      alt_name: "deeplearning.ai",
      color_code: "",
    },   
  ],
};

// Experience Page
const experience = {
  title: "Experience",
  subtitle: "Internship and Volunteership",
  description:
    "I have worked with many evolving startups as ML and DL Developer, Designer and Software Architect. I have also worked with some well established companies mostly as AI Developer. I love organising events and that is why I am also involved with many opensource communities as a representative and even leaded some open-source community.",
  header_image_path: "experience.svg",
  sections: [
    {
      title: "Internships",
      experiences: [
        {
          title: "Research & Development Intern",
          company: "ARDE-DRDO [ Defence Research & Development Organisation ]",
          company_url: "https://www.drdo.gov.in/",
          logo_path: "drdo.png",
          duration: "Jul 2022 - Oct 2022",
          location: "Pune, Maharashtra",
          description:
               "Research Internship (internship) involved the use of robotic sensors (LIS331HH) and a Beaglebone processor to gather audio data files. Data augmentation techniques were used to produce extra data without changing its significance. A DL Model was created with a batch size of 32 and an accuracy of 97%.",
               color: "#000000",
        },
        {
          title: "Sr. ML(NLP) Intern",
          company: "Indian Institute of Management, Udaipur",
          company_url: "https://www.iimu.ac.in/",
          logo_path: "iim.png",
          duration: "Nov 2021 - Present",
          location: "Udaipur, Rajasthan",
          description:
            "Deduction in Computational Time: We were able to reduce computation time for text extraction from a PDF to 0.1 seconds per page. Program Accuracy: I improved accuracy from 90% to 98.8%. ASI Data: Working on combining Nester files into single SATA file.",
          color: "#ee3c26",
        },
        {
          title: "AI Intern",
          company: "Iassist Inovation Labs",
          company_url:
            "https://claimsftr.com/",
          logo_path: "ias.png",
          duration: "Nov 2023 - Present",
          location: "Work From Home",
          color: "#0071C5",
        },
      ],
    },
    {
      title: "Volunteerships",
      experiences: [
        {
          title: " Founder & President",
          company: "Mozilla FireFox Club VIT Bhopal",
          company_url: "https://www.mozillafirefoxvitb.com/#about-us",
          logo_path: "mfc.png",
          duration: "June 2019 - April 2020",
          location: "Hyderabad, Telangana",
          description:
            "As the former president and founder of the Mozilla Firefox Club at VIT Bhopal, I had the honor of leading an organization that was committed to promoting open-source software and browser technology.During my tenure, I organized three highly successful events, including Codefox, which received over 11 lakh viewers and 1000+ registrations and was featured as a top event on Unstop. It was truly an achievement to see the impact that these events had on the student community, and I am proud to have been a part of it.",
          color: "#4285F4",
        },
        {
          title: "Vice President",
          company: "Codecademy VIT Bhopal Chapter",
          company_url: "https://www.codecademy.com/",
          logo_path: "code.png",
          duration: "Sep 2020 - Sep 2023",
          location: "Bhopal, Madhya Pradesh",
          description:
            "As I reflect on my tenure as the Vice President of the Codecademy VIT Bhopal Chapter, I am filled with a sense of pride and accomplishment. During my time in this leadership role, I have worked tirelessly to promote coding and computer science in our campus community.Together with the executive board, we have organized various events and initiatives, such as coding contests, hackathons, and workshops. These events were aimed at fostering a culture of innovation and learning among our members, and I am proud to say that they were highly successful.",
          color: "#D83B01",
        },
        {
          title: "Intel Student Ambassador",
          company: "Intel",
          company_url: "https://www.intel.com/content/www/us/en/homepage.html",
          logo_path: "intel.png",
          duration: "Oct 2019 - May 2020",
          location: "Kurnool, Andhra Pradesh",
          color: "#000000",
        },
        {
          title: "Campus Ambasador - Sales Marketing",
          company: "E-cell IIT Bombay",
          company_url:
            "https://www.ecell.in/2020/",
          logo_path: "ecell.png",
          duration: "May 2021 - Sept 2021",
          location: "Mumbai, Maharashtra",
          description:
            "I am proud to have been awarded a Silver Badge for my work as a connection between the students of my college and E-cell IIT-Bombay. During my tenure, I successfully attracted a minimum of 100+ audience members to events and helped circulate valuable entrepreneurship opportunities from E-cell IIT-Bombay to young entrepreneurs in my region. One of my most fulfilling responsibilities was guiding and developing over 50 students towards their chosen career paths. I am passionate about helping others find success and I believe my work with E-cell IIT-Bombay allowed me to do just that.",
          color: "#0C9D58",
        },
        {
          title: "Campus Ambasador - Sales Marketing",
          company: "Techlearn.live",
          company_url: "https://github.com/",
          logo_path: "techlearn.png",
          duration: "July 2021 - Sept 2021",
          location: "Work From Home",
          description:
            "I had the opportunity to intern as a student ambassador with tech learn.live, where I successfully organized events that attracted 40-50+ attendees for each event. As the person in charge, I was also able to successfully market and increase the reach of the company to the student community.During my tenure, I helped the company recruit new campus ambassadors to continue the process after my tenure ended. My experience with tech learn.live allowed me to develop my skills as an event organizer and marketer, while also contributing to the growth of the company.",
          color: "#181717",
        },
      ],
    },
  ],
};

// Projects Page
const projectsHeader = {
  title: "Projects",
  description:
    "Welcome to my personal projects page! Here, you'll find a collection of projects that I've worked on, with a focus on machine learning and natural language processing. These projects cover a range of topics, including sentiment analysis, chatbots, and image recognition. Each project comes with a detailed description of the tech stack used and a GitHub link to access the code. I'm passionate about exploring the potential of these emerging technologies and using them to solve real-world problems. I hope you'll find these projects as exciting as I did!",
  avatar_image_path: "projects_image.svg",
};

const publicationsHeader = {
  title: "Publications",
  description:
    "I have worked on and published a few research papers and publications of my own.",
  avatar_image_path: "projects_image.svg",
};

const publications = {
  data: [
    {
      id: "MDEwOlJlcG9zaXRvcnkyNDU0NjcyNzQ=",
      name: "Artificial Intelligence Paper",
      createdAt: "2020-03-06T16:26:54Z",
      description: "Paper Written on Artificial Intelligence published in xyz ",
      url:
        "https://www.andrewng.org/publications/building-high-level-features-using-large-scale-unsupervised-learning/",
    },
    {
      id: "MDEwOlJlcG9zaXRvcnkyNDU0NjcyNzi=",
      name: "Artificial Intelligence Paper",
      createdAt: "2020-03-06T16:26:54Z",
      description: "Paper Written on Artificial Intelligence published in xyz ",
      url:
        "https://www.andrewng.org/publications/building-high-level-features-using-large-scale-unsupervised-learning/",
    },
    {
      id: "MDEwOlJlcG9zaXRvcnkyNDU0NjcyNze=",
      name: "Artificial Intelligence Paper",
      createdAt: "2020-03-06T16:26:54Z",
      description: "Paper Written on Artificial Intelligence published in xyz ",
      url:
        "https://www.andrewng.org/publications/building-high-level-features-using-large-scale-unsupervised-learning/",
    },
    {
      id: "MDEwOlJlcG9zaXRvcnkyNDU0NjcyNzt=",
      name: "Artificial Intelligence Paper",
      createdAt: "2020-03-06T16:26:54Z",
      description: "Paper Written on Artificial Intelligence published in xyz ",
      url:
        "https://www.andrewng.org/publications/building-high-level-features-using-large-scale-unsupervised-learning/",
    },
    {
      id: "MDEwOlJlcG9zaXRvcnkyNDU0NjcyNzb=",
      name: "Artificial Intelligence Paper",
      createdAt: "2020-03-06T16:26:54Z",
      description: "Paper Written on Artificial Intelligence published in xyz ",
      url:
        "https://www.andrewng.org/publications/building-high-level-features-using-large-scale-unsupervised-learning/",
    },
  ],
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Contact Me",
    profile_image_path: "animated_ashutosh.png",
    description:
      "I am available on almost every social media. You can message me, I will reply within 24 hours. I can help you with ML, AI, React, Android, Cloud and Opensource Development.",
  },
  blogSection: {
    title: "Blogs",
    subtitle:
      "For individual fundamental empowerment, I like to write powerful lessons that create impact on each of the reader individually to change the core of their character.",
    link: "https://blogs.ashutoshhathidara.com/",
    avatar_image_path: "blogs_image.svg",
  },
  addressSection: {
    title: "Address",
    subtitle:
      "Ambavadi vas, Kanodar, T.A.-Palanpur, Dist.-Banaskantha, Gujarat - 385520",
    avatar_image_path: "address_image.svg",
    location_map_link: "https://goo.gl/maps/MpMqtRZytFchMkZ76",
  },
  phoneSection: {
    title: "",
    subtitle: "",
  },
};

export {
  settings,
  seo,
  greeting,
  socialMediaLinks,
  skills,
  competitiveSites,
  degrees,
  certifications,
  experience,
  projectsHeader,
  publicationsHeader,
  publications,
  contactPageData,
};
